import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const DrPanLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Pan"
    institution="Dr. Pan"
    referralCode="DRPAN"
    physicianURL="https://www.shupanmd.com"
  />
)

export default DrPanLandingPage
